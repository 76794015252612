:root {
  --primary: -webkit-linear-gradient(90deg, #fff, #bfc0c0);
  --secondary: linear-gradient(0deg, #bfc0c0, #ffffff);
  --nav: #4f5d75;
  --title: #2d3142;
  --cta: #ef8354;
}

@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  color: unset;
}

#main {
  margin-top: -18px;
}

#cta {
  margin-top: -2%;
  color: var(--cta);
  margin-bottom: 30px;
  text-align: center; /* Center align the text (optional) */
  font-size: 17px; /* Initial font size, adjust as needed */
  font-weight: bold; /* Bold text to enhance visibility */
}

#title {
  font-size: 70px;
  font-family: 'Anton', sans-serif;
  text-align: center;
  font-style: italic;
  font-weight: 600;
  color: var(--title);
  text-shadow: -4px 4px 8px rgba(0, 0, 0, 0.7);
  position: relative;
  top: -33px;
}

.list {
  text-align: left;
  list-style-position: inside;
  margin-right: 15%;
  padding-left: 15%;
}
.toolz {
  font-size: 15px;
}
body {
  background: var(--primary);
}
#bg {
  margin: 2% 3% 11% 3%;
  padding-top: 10px;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
}
#bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 6%;
  right: 6%;
  bottom: 0;
  z-index: -1;
  border-radius: 10px; /* Match parent's border-radius */
  background-image: var(--secondary);
  margin-bottom: -2em;
}

nav {
  padding: 10px;
  padding-left: 7%;
  padding-right: 7%;
}

#list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-right: 2em;
  margin-left: 0;
  margin-bottom: -4px;
  font-family: 'Anton', sans-serif;
  font-style: italic;
}

.items {
  background-color: rgba(216, 216, 216, 0);
  margin-right: 10px;
}
.items a {
  color: var(--nav);
}

.items:last-child {
  margin-right: 0;
}

.home-link {
  margin-right: auto;
}

/* .items a {
  text-decoration: none;
  color: #333;
  padding: 5px;
  border-radius: 5%;
}

.items a:hover {
  background-color: #dddddd23;
} */

/* emojis */

li.toolz span a {
  color: black;
  text-decoration: none;
  font-size: 20px; /* Adjust the font size as needed */
  background: none; /* Remove any background color */
  -webkit-text-fill-color: initial; /* Reset text fill color */
}

/* 

  TOOL TIPS =======================>

*/

.tooltipimg {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.toolz:hover .tooltipimg {
  visibility: visible;
}

/* login form bg */
.card-body {
  background: var(--primary);
  border-radius: 22px;
}
.card {
  border-radius: 24px;
}
