/* Footer1.css */
.footer1 {
  text-align: center;
  padding: 1em;
  background-color: #f8f9fa00;
  position: absolute;
  width: 100%;
  font-size: small;
  margin-top: 4em;
}

.footer1 p {
  margin: 0;
  color: #333;
}
